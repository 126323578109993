<template>
    <div class="mx-4">
        <div>
            <h4
                class="text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs">
                Who is your favourite InstaGlam Girl?</h4>
        </div>


        <div class="mt-4">
            <div class="grid gap-2 md:gap-4 grid-cols-2 md:grid-cols-4 items-end">
                <div class="col-span-1 flex justify-center items-end" v-for="(image_file, character_name) in characters">
                    <label
                        class="cursor-pointer rounded block border-4 border-transparent"
                        v-bind:class="{ 'border-instaglam-pink shadow-lg': value.character === character_name }">
                        <img class="w-24 md:w-32 lg:w-48 h-auto max-w-xs" :src="'images/questionnaire/character/' + image_file" :alt="character_name">

                        <input class="hidden" type="radio" name="character" :value="character_name" v-model="value.character">
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Form from "../Partials/Form";

    export default {
        components: {},
        props: [
            'value',
        ],
        data() {
            return {
                characters: {
                    'hayley': 'hayley.png',
                    'luna': 'luna.png',
                    'nina': 'nina.png',
                    'evie': 'evie.png',
                },
                form: new Form(),
            }
        },
        mounted() {
            this.$emit('input', {
                ...this.value,
                key: 'character',
            });
        },
        watch: {
            //
        },
        methods: {
            next() {
                this.$emit('next-step');
            },
        },
    }
</script>
