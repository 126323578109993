$(document).ready(function () {
    // $('#girls-slider').slick({
    //     center: true,
    //     dots: false,
    //     infinite: true,
    //     slidesToShow: 4,
    //     arrows: false,
    //     responsive: [
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 1
    //             }
    //         },
    //         {
    //             breakpoint: 992,
    //             settings: {
    //                 slidesToShow: 3
    //             }
    //         }
    //     ]
    // });

    // $('#product-carousel-prev').on('click', function () {
    //     $('#product-carousel').slick('slickPrev');
    // });

    // $('#product-carousel-next').on('click', function () {
    //     $('#product-carousel').slick('slickNext');
    // });

    // $('#product-carousel').slick({
    //     center: true,
    //     dots: false,
    //     infinite: true,
    //     slidesToShow: 3,
    //     slidesToScroll: 3,
    //     arrows: false,
    //     responsive: [
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 2,
    //             }
    //         },
    //         {
    //             breakpoint: 992,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 2,
    //             }
    //         }
    //     ]
    // });
});
