<template>
    <div class="mx-4">
        <div>
            <h4
                class="text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs">
                My Best Life...</h4>
        </div>

        <div class="mt-12">
            <div class="grid gap-3 grid-cols-2 md:grid-cols-4 items-start">
                <div class="col-span-1 h-full flex flex-col items-center" v-for="(option, option_name) in options">
                    <label
                        class="h-full cursor-pointer rounded block border-4 border-transparent md:p-4 border-gray-100"
                        v-bind:class="{ 'border-instaglam-pink shadow-lg': value.best_life === option_name }">

                        <input class="hidden" type="radio" name="best_life" :value="option_name"
                               v-model="value.best_life">

                        <span class="h-full inline-flex flex-col items-center justify-between select-none text-center">
                            <span class="pt-2 md:p-0 inline-block w-full text-xs md:text-base text-center text-lg font-bold">{{ option.title }}</span>
                            <span class="px-2 md:p-0 flex-1 mt-4 w-auto md:w-40 text-xs md:text-base flex justify-center items-center">{{ option.content }}</span>
                        </span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Form from "../Partials/Form";

    export default {
        components: {},
        props: [
            'value',
        ],
        data() {
            return {
                options: {
                    'social': {
                        'title': '100% Social',
                        'content': 'I\'m happiest when I\'m with my friends!',
                    },
                    'fun': {
                        'title': '100% Fun',
                        'content': 'There\'s never a dull moment with me around',
                    },
                    'dreamy': {
                        'title': '100% Dreamy',
                        'content': 'I love to laze in the garden surrounded by pretty flowers',
                    },
                    'chilled': {
                        'title': '100% Chilled',
                        'content': 'Chillin\' with my friends and creating super cool make up looks',
                    },
                },
                form: new Form(),
            }
        },
        mounted() {
            this.$emit('input', {
                ...this.value,
                key: 'best_life',
            });
        },
        watch: {
            //
        },
        methods: {
            next() {
                this.$emit('next-step');
            },
        },
    }
</script>
