var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mx-4" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "mt-4" }, [
      _c(
        "div",
        { staticClass: "grid gap-4 grid-cols-2 md:grid-cols-4 " },
        _vm._l(_vm.colours, function(colour_code, colour_name) {
          return _c(
            "div",
            { staticClass: "col-span-1 p-4 flex flex-col items-center" },
            [
              _c(
                "label",
                {
                  staticClass:
                    "cursor-pointer inline-block rounded-full w-24 lg:w-48 h-24 lg:h-48 border-4 border-transparent",
                  class: {
                    "border-black shadow-lg": _vm.value.colour === colour_name
                  },
                  style: "background-color: " + colour_code + ";"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.value.colour,
                        expression: "value.colour"
                      }
                    ],
                    staticClass: "hidden",
                    attrs: { type: "radio", name: "colour" },
                    domProps: {
                      value: colour_name,
                      checked: _vm._q(_vm.value.colour, colour_name)
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.value, "colour", colour_name)
                      }
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "text-center mt-2" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm._f("capitalize")(colour_name)) +
                    "\n                "
                )
              ])
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "h4",
        {
          staticClass:
            "text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs"
        },
        [_vm._v("\n            What is your favourite colour?")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }