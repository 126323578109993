var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mx-4 mt-8" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "mt-4" }, [
      _c("div", { staticClass: "flex flex-col-reverse md:flex-row" }, [
        _c("div", { staticClass: "mt-8 md:mt-4 md:w-2/3 mx-8 mx-4" }, [
          _c("div", { staticClass: "mt-1 relative rounded-md shadow-sm" }, [
            _c("label", { attrs: { for: "name" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.value.name,
                    expression: "value.name"
                  }
                ],
                staticClass: "form-input block w-full sm:text-sm sm:leading-5",
                attrs: {
                  id: "name",
                  placeholder: "Claire",
                  "aria-describedby": "name-description"
                },
                domProps: { value: _vm.value.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.value, "name", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "mt-2 text-sm text-gray-500",
              attrs: { id: "name-description" }
            },
            [_vm._v("Just your first one is fine!")]
          )
        ]),
        _vm._v(" "),
        _vm._m(1)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "h4",
        {
          staticClass:
            "text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs"
        },
        [_vm._v("\n        What is your name?")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex justify-center mt-4 md:mt-0 md:block" },
      [
        _c("img", {
          staticClass: "md:mt-4 w-56 md:w-full h-auto max-w-xs",
          attrs: { src: "images/logo.png", alt: "Instaglam" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }