var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mx-4" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "mt-4" }, [
      _c(
        "div",
        { staticClass: "grid gap-6 grid-cols-2 md:grid-cols-4 items-end" },
        _vm._l(_vm.emojis, function(image_file, emoji_name) {
          return _c("div", { staticClass: "col-span-1" }, [
            _c(
              "label",
              {
                staticClass:
                  "cursor-pointer inline-block rounded-full border-4 border-transparent",
                class: {
                  "border-black bg-black shadow-lg":
                    _vm.value.emoji === emoji_name
                }
              },
              [
                _c("img", {
                  staticClass: "w-24 lg:w-48 h-auto max-w-xs",
                  attrs: {
                    src: "images/questionnaire/emoji/" + image_file,
                    alt: emoji_name
                  }
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.value.emoji,
                      expression: "value.emoji"
                    }
                  ],
                  staticClass: "hidden",
                  attrs: { type: "radio", name: "emoji" },
                  domProps: {
                    value: emoji_name,
                    checked: _vm._q(_vm.value.emoji, emoji_name)
                  },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.value, "emoji", emoji_name)
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "text-center" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm._f("capitalize")(emoji_name)) +
                  "\n                "
              )
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "h4",
        {
          staticClass:
            "text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs"
        },
        [_vm._v("\n            Choose an Insta Emoji\n        ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }