var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mx-4" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "mt-4" }, [
      _c(
        "div",
        { staticClass: "grid gap-6 grid-cols-2 md:grid-cols-4 items-start" },
        _vm._l(_vm.options, function(image_file, option_name) {
          return _c(
            "div",
            { staticClass: "col-span-1 flex flex-col items-center" },
            [
              _c(
                "label",
                {
                  staticClass:
                    "cursor-pointer rounded block border-4 border-transparent p-4",
                  class: {
                    "border-instaglam-pink shadow-lg":
                      _vm.value.makeupvibe === option_name
                  }
                },
                [
                  _c("img", {
                    staticClass: "w-24 lg:w-48  h-auto max-w-xs",
                    attrs: {
                      src: "images/questionnaire/makeupvibe/" + image_file,
                      alt: option_name
                    }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.value.makeupvibe,
                        expression: "value.makeupvibe"
                      }
                    ],
                    staticClass: "hidden",
                    attrs: { type: "radio", name: "makeupvibe" },
                    domProps: {
                      value: option_name,
                      checked: _vm._q(_vm.value.makeupvibe, option_name)
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.value, "makeupvibe", option_name)
                      }
                    }
                  })
                ]
              )
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "h4",
        {
          staticClass:
            "text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs"
        },
        [_vm._v("\n            My makeup vibe is...")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }