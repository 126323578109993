var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.open, expression: "open" }
      ]
    },
    [
      _c(
        "div",
        {
          staticClass:
            "z-10 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        },
        [
          _c("div", { staticClass: "p-4" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.close,
                    expression: "close"
                  }
                ],
                staticClass: "rounded-lg p-2 bg-black relative"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "absolute top-0 right-0 z-20",
                    staticStyle: {
                      transform: "translateX(25%) translateY(-25%)"
                    }
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "inline-flex justify-center items-center bg-white w-8 h-8 rounded-full bg-instaglam-pink text-white",
                        on: { click: _vm.close }
                      },
                      [_c("i", { staticClass: "fas fa-lg fa-times" })]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "overflow-x-hidden overflow-y-scroll scrollbar-none",
                    staticStyle: { "max-height": "95vh", "max-width": "95vw" }
                  },
                  [_vm._t("default")],
                  2
                )
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }