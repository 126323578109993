var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full flex flex-wrap bg-white rounded overflow-hidden" },
    [
      _c(
        "div",
        {
          staticClass:
            "pt-4 px-4 w-full text-center lg:hidden flex justify-center"
        },
        [
          _c(
            "h1",
            {
              staticClass:
                "text-center font-instaglam text-instaglam-pink text-2xl font-bold text-shadow-instaglam uppercase"
            },
            [_vm._v(_vm._s(_vm.model.name))]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "\n                w-full flex flex-col items-center\n                lg:w-1/2\n        "
        },
        [
          _c("div", { staticClass: "px-8 mt-4 lg:mb-4" }, [
            _c(
              "div",
              { staticClass: "bg-gray-100 p-1 lg:p-2 rounded-md shadow-md" },
              [
                _c("img", {
                  staticClass: "object-contain",
                  staticStyle: { "max-height": "50vh" },
                  attrs: {
                    src: _vm.selected_image.file,
                    srcset: _vm.selected_image.srcset,
                    alt: _vm.model.name
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "h-full w-11/12 lg:w-5/6 px-8 lg:px-0 mt-4 lg:mt-0"
            },
            [
              _c(
                "Slick",
                {
                  ref: "productimages",
                  attrs: { options: _vm.slickNavOptions }
                },
                _vm._l(_vm.model.images, function(image) {
                  return _c(
                    "div",
                    {
                      key: image.id,
                      on: {
                        click: function($event) {
                          _vm.selected_image = image
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "w-full flex justify-center px-1" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "bg-gray-100 p-1 lg:p-2 rounded-md shadow-md"
                            },
                            [
                              _c("img", {
                                staticClass: "w-30 h-30 lg:w-36 lg:h-36",
                                attrs: { src: image.thumb, alt: _vm.model.name }
                              })
                            ]
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "w-full lg:w-1/2 mt-4 overflow-y-scroll px-2 pb-4",
          staticStyle: { "max-height": "95vh" }
        },
        [
          _c("div", { staticClass: "hidden lg:block" }, [
            _c(
              "h1",
              {
                staticClass:
                  "text-center font-instaglam text-instaglam-pink text-2xl xl:text-4xl font-bold text-shadow-instaglam uppercase"
              },
              [_vm._v(_vm._s(_vm.model.name))]
            )
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "lg:mt-8",
            domProps: { innerHTML: _vm._s(_vm.model.content) }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "mt-4 mb-8 text-center" }, [
            _c(
              "a",
              {
                staticClass:
                  "inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-instaglam-600 hover:bg-instaglam-500 focus:outline-none focus:border-instaglam-700 focus:shadow-outline-instaglam active:bg-instaglam-700 transition ease-in-out duration-150",
                attrs: { href: _vm.model.url, target: "_blank" }
              },
              [_vm._v(_vm._s(_vm.model.link_text))]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }