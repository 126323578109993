<template>
    <div class="mx-4">
        <div>
            <h4
                class="text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs">
                What is your favourite food?</h4>
        </div>


        <div class="mt-4">
            <div class="grid gap-6 grid-cols-2 md:grid-cols-4">
                <div class="col-span-1 flex flex-col items-center" v-for="(image_file, food_name) in food">
                    <label
                        class="cursor-pointer inline-block rounded-full border-4 border-transparent"
                        v-bind:class="{ 'border-black bg-black shadow-lg': value.food === food_name }">
                        <img class="w-24 lg:w-48  h-auto max-w-xs"
                             :src="'images/questionnaire/food/' + image_file"
                             :alt="food_name">
                        <input class="hidden" type="radio" name="food" :value="food_name" v-model="value.food">
                    </label>
                    <div class="text-center">
                        {{ food_name | deslug | capitalize_words }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Form from "../Partials/Form";

    export default {
        components: {},
        props: [
            'value',
        ],
        data() {
            return {
                food: {
                    'cupcakes': 'cupcakes.png',
                    'ice_cream': 'ice_cream.png',
                    'lollipops': 'lollipops.png',
                    'marshmallows': 'marshmallows.png',
                },
                form: new Form(),
            }
        },
        mounted() {
            this.$emit('input', {
                ...this.value,
                key: 'food',
            });
        },
        watch: {
            //
        },
        methods: {
            next() {
                this.$emit('next-step');
            },
        },
    }
</script>
