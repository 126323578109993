var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", { attrs: { open: _vm.open }, on: { close: _vm.close } }, [
    _vm.open
      ? _c("div", { staticClass: "grid gap-1 grid-cols-4" }, [
          _c(
            "div",
            { staticClass: "col-span-4 md:col-span-3 relative" },
            [
              _vm.selected !== ""
                ? [
                    _c("vue-core-video-player", {
                      attrs: {
                        title: _vm.selected.name,
                        autoplay: true,
                        controls: true,
                        cover: _vm.selected.video.thumb,
                        src: _vm.selected.video.file
                      }
                    })
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-span-4 md:col-span-1 relative" }, [
            _c(
              "div",
              {
                staticClass:
                  "\n                relative scrollbar-none\n                md:absolute inset-0 h-full\n                md:flex md:flex-col\n                md:overflow-y-scroll md:overflow-x-hidden\n                overflow-y-hidden overflow-x-scroll\n                whitespace-no-wrap\n                "
              },
              _vm._l(_vm.videos, function(video) {
                return _c(
                  "div",
                  {
                    staticClass:
                      "relative group cursor-pointer p-1 w-1/2 md:w-1/3 inline-block md:w-full h-full md:h-auto",
                    on: {
                      click: function($event) {
                        return _vm.selectVideo(video)
                      }
                    }
                  },
                  [
                    _c("div", {
                      staticClass:
                        "group-hover:hidden absolute inset-0 bg-black bg-opacity-25"
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "absolute inset-0 flex justify-center items-center",
                        staticStyle: { "z-index": "1" }
                      },
                      [
                        _c("img", {
                          staticClass: "w-16 lg:w-24 object-contain",
                          attrs: { src: "images/video-play.png", alt: "Play" }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-sm font-semibold text-white bg-black bg-opacity-50",
                        staticStyle: {
                          position: "absolute",
                          left: "5px",
                          top: "5px",
                          padding: "3px 6px",
                          "border-radius": "2px"
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(video.name) +
                            "\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-xs font-semibold text-white bg-black bg-opacity-50",
                        staticStyle: {
                          position: "absolute",
                          right: "5px",
                          bottom: "5px",
                          padding: "3px 6px",
                          "border-radius": "2px"
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              new Date(video.duration * 1000)
                                .toISOString()
                                .substr(14, 5)
                            ) +
                            "\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "h-full w-full object-cover",
                      attrs: { src: video.video.thumb, alt: video.name }
                    })
                  ]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }