var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mx-4" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "mt-12" }, [
      _c(
        "div",
        { staticClass: "grid gap-3 grid-cols-2 md:grid-cols-4 items-start" },
        _vm._l(_vm.options, function(option, option_name) {
          return _c(
            "div",
            { staticClass: "col-span-1 h-full flex flex-col items-center" },
            [
              _c(
                "label",
                {
                  staticClass:
                    "h-full cursor-pointer rounded block border-4 border-transparent md:p-4 border-gray-100",
                  class: {
                    "border-instaglam-pink shadow-lg":
                      _vm.value.best_life === option_name
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.value.best_life,
                        expression: "value.best_life"
                      }
                    ],
                    staticClass: "hidden",
                    attrs: { type: "radio", name: "best_life" },
                    domProps: {
                      value: option_name,
                      checked: _vm._q(_vm.value.best_life, option_name)
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.value, "best_life", option_name)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "h-full inline-flex flex-col items-center justify-between select-none text-center"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "pt-2 md:p-0 inline-block w-full text-xs md:text-base text-center text-lg font-bold"
                        },
                        [_vm._v(_vm._s(option.title))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "px-2 md:p-0 flex-1 mt-4 w-auto md:w-40 text-xs md:text-base flex justify-center items-center"
                        },
                        [_vm._v(_vm._s(option.content))]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "h4",
        {
          staticClass:
            "text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs"
        },
        [_vm._v("\n            My Best Life...")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }