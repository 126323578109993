<template>
    <div class="mx-4">
        <div>
            <h4
                class="text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs">
                Choose an Insta Emoji
            </h4>
        </div>

        <div class="mt-4">
            <div class="grid gap-6 grid-cols-2 md:grid-cols-4 items-end">
                <div class="col-span-1" v-for="(image_file, emoji_name) in emojis">
                    <label
                        class="cursor-pointer inline-block rounded-full border-4 border-transparent"
                        v-bind:class="{ 'border-black bg-black shadow-lg': value.emoji === emoji_name }">
                        <img class="w-24 lg:w-48 h-auto max-w-xs"
                             :src="'images/questionnaire/emoji/' + image_file"
                             :alt="emoji_name">
                        <input class="hidden" type="radio" name="emoji" :value="emoji_name" v-model="value.emoji">
                    </label>
                    <div class="text-center">
                        {{ emoji_name | capitalize }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Form from "../Partials/Form";

    export default {
        components: {},
        props: [
            'value',
        ],
        data() {
            return {
                emojis: {
                    'hearts': 'hearts.png',
                    'smile': 'smile.png',
                    'sunglasses': 'sunglasses.png',
                    'tongue': 'tongue.png',
                },
                form: new Form(),
            }
        },
        mounted() {
            this.$emit('input', {
                ...this.value,
                key: 'emoji',
            });
        },
        watch: {
            //
        },
        methods: {
            next() {
                this.$emit('next-step');
            },
        },
    }
</script>
