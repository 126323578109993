<template>
    <div class="mx-4">
        <div>
            <h4
                class="text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs">
                My makeup finish is...</h4>
        </div>

        <div class="mt-4">
            <div class="grid gap-6 grid-cols-2 md:grid-cols-4 items-end">
                <div class="col-span-1 flex flex-col items-center" v-for="(image_file, option_name) in options">
                    <label
                        class="cursor-pointer rounded block border-4 border-transparent p-4"
                        v-bind:class="{ 'border-instaglam-pink shadow-lg': value.makeupfinish === option_name }">
                        <img class="w-24 lg:w-48  h-auto max-w-xs" :src="'images/questionnaire/makeupfinish/' + image_file"
                             :alt="option_name">

                        <input class="hidden" type="radio" name="makeupfinish" :value="option_name" v-model="value.makeupfinish">
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Form from "../Partials/Form";

    export default {
        components: {},
        props: [
            'value',
        ],
        data() {
            return {
                options: {
                    'glitter': 'glitter.png',
                    'shimmer': 'shimmer.png',
                    'shine': 'shine.png',
                    'sparkle': 'sparkle.png',
                },
                form: new Form(),
            }
        },
        mounted() {
            this.$emit('input', {
                ...this.value,
                key: 'makeupfinish',
            });
        },
        watch: {
            //
        },
        methods: {
            next() {
                this.$emit('next-step');
            },
        },
    }
</script>
