<template>
    <Modal :open="open" @close="close">
        <div class="bg-white rounded overflow-hidden">
            <div v-show="!complete">
                <div class="mt-2">
                    <h1
                        class="font-instaglam font-bold
                        text-center
                        text-3xl text-instaglam-pink text-shadow-instaglam-sm">
                        Question {{ step }} of {{ this.steps.length }}
                    </h1>
                </div>

                <div class="my-4">
                    <component
                        :is="currentComponent"
                        v-model="data"
                    ></component>

                    <div v-show="error !== ''" class="mt-4 text-center text-red-500 font-semibold">
                        {{ error }}
                    </div>
                </div>

                <div class="mt-4 flex justify-between px-8 pb-4">
                    <div>
                        <button
                            class="form-input"
                            :disabled="this.step <= 1"
                            v-bind:class="{ 'cursor-not-allowed': this.step <= 1 }"
                            @click="prevStep">Previous
                        </button>
                    </div>
                    <div>
                        <button
                            class="form-input"
                            @click="nextStep">
                            <span v-if="this.step < this.steps.length">Next</span>
                            <span v-else>Find My Style!</span>
                        </button>
                    </div>
                </div>
            </div>

            <Result v-model="data" v-if="complete" @reset="startOver"/>
        </div>
    </Modal>
</template>

<script>
    import Modal from "./Modal";

    import AnimalForm from "./forms/AnimalForm";
    import BestLifeForm from "./forms/BestLifeForm";
    import CharacterForm from "./forms/CharacterForm";
    import ColourForm from "./forms/ColourForm";
    import DescribeYourselfForm from "./forms/DescribeYourselfForm";
    import DrinkForm from "./forms/DrinkForm";
    import EmojiForm from "./forms/EmojiForm";
    import FoodForm from "./forms/FoodForm";
    import HolidayForm from "./forms/HolidayForm";
    import LoveForm from "./forms/LoveForm";
    import MakeUpFinishForm from "./forms/MakeUpFinishForm";
    import MakeUpVibeForm from "./forms/MakeUpVibeForm";
    import MusicForm from "./forms/MusicForm";
    import NameForm from "./forms/NameForm";
    import Result from "./forms/Result";

    export default {
        name: 'Questionnaire',
        components: {
            Modal,
            Result,
        },
        props: [
            'open',
        ],
        data() {
            return {
                step: '',
                steps: [
                    NameForm, // 1
                    CharacterForm, // 2
                    ColourForm, // 3
                    EmojiForm, // 4
                    DescribeYourselfForm, // 5
                    FoodForm, // 6
                    DrinkForm, // 7
                    LoveForm, // 8
                    MakeUpFinishForm, // 9
                    MakeUpVibeForm, // 10
                    AnimalForm, // 11
                    MusicForm, // 12
                    HolidayForm, // 13
                    BestLifeForm, // 14
                ],
                currentComponent: '',
                complete: false,
                error: '',
                data: {
                    // "colour": "purple",
                    // "emoji": "sunglasses",
                    // "best_life": "dreamy",
                    // "name": "Test",
                    // "character": "luna",
                    // "describe_word_1": "Test1",
                    // "describe_word_2": "Test2",
                    // "describe_word_3": "Test3",
                    // "food": "cupcakes",
                    // "drink": "energy_juices",
                    // "love": "unicorns",
                    // "makeupfinish": "shine",
                    // "makeupvibe": "gem_queen",
                    // "animal": "rabbits",
                    // "music": "pop",
                    // "holiday": "camping"
                }
            }
        },
        mounted() {
            this.step = 1;
            this.syncComponent();
        },
        watch: {
            //
        },
        methods: {
            close: function () {
                if(this.complete === true) {
                    this.startOver();
                }

                this.$emit('close');
            },
            prevStep: function () {
                if (this.step > 1) {
                    this.step = this.step - 1;
                }

                this.syncComponent();
            },
            nextStep: function () {
                // Reset error message
                this.error = '';

                const self = this;
                if ('key' in this.data) {
                    let key_value = this.data.key;
                    if (!(key_value instanceof Array)) {
                        key_value = [key_value];
                    }
                    key_value = key_value.filter(String);

                    key_value.forEach(function (key) {
                        if (self.data[key] === '' || !(key in self.data)) {
                            self.error = "Please provide your answer.";
                        }
                    });

                    if(self.error !== '') {
                        // If an error message has been set, we return here to stop it moving onto next step.
                        return;
                    }
                }

                const count = this.steps.length;

                if (this.step === count) {
                    this.complete = true;
                } else if (this.step < count) {
                    this.step = this.step + 1;
                }

                this.syncComponent();
            },
            startOver: function() {
                this.complete = false;
                this.step = 1;
                this.data = {};
                this.syncComponent();
            },
            syncComponent: function () {
                this.currentComponent = this.steps[this.step - 1];
            }
        },
    }
</script>
