var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mx-4 my-8" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt-6" },
      [
        _vm.image_url === ""
          ? [_vm._m(1)]
          : [
              _c(
                "div",
                { staticClass: "bg-instaglam-pink p-1 rounded shadow-md" },
                [
                  _c(
                    "a",
                    { attrs: { href: _vm.image_url, target: "_blank" } },
                    [
                      _c("img", {
                        staticClass: "max-w-xl object-contain w-full",
                        attrs: { src: _vm.image_url, alt: "My Instaglam Style" }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-4 flex justify-center p-1 md:p-2" },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "shadow-md mx-2 md:mx-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-instaglam-600 hover:bg-instaglam-500 focus:outline-none focus:border-instaglam-700 focus:shadow-outline-instaglam active:bg-instaglam-700 transition ease-in-out duration-150",
                      attrs: {
                        href: _vm.image_url + "?download=1",
                        target: "_blank"
                      }
                    },
                    [_vm._v("Save Print Out")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "shadow-md mx-2 md:mx-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-instaglam-600 hover:bg-instaglam-500 focus:outline-none focus:border-instaglam-700 focus:shadow-outline-instaglam active:bg-instaglam-700 transition ease-in-out duration-150",
                      attrs: { href: _vm.image_url, target: "_blank" }
                    },
                    [_vm._v("View Print Out")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "shadow-md mx-2 md:mx-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-instaglam-600 hover:bg-instaglam-500 focus:outline-none focus:border-instaglam-700 focus:shadow-outline-instaglam active:bg-instaglam-700 transition ease-in-out duration-150",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("reset")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Start Over\n                "
                      )
                    ]
                  )
                ]
              )
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "h4",
        {
          staticClass:
            "text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs"
        },
        [_vm._v("\n            Your Instaglam Style!")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" }),
      _vm._v(" "),
      _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }