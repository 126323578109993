<template>
    <div class="w-full">
        <div class="w-full flex justify-center">
            <div class="hidden pl-2 md:pl-0 md:flex items-center">
                <div class="cursor-pointer mb-4 w-6 h-6 md:w-16 md:h-16"
                    @click="prev">
                    <img src="images/button-previous.png" alt="Previous">
                </div>
            </div>

            <div class="w-full overflow-hidden">
                <slick
                    id="product-carousel"
                    ref="slick"
                    :options="slickOptions">
                    <template v-for="product in products">
                        <div :key="product.id">
                            <div class="cursor-pointer" @click="selectProduct(product)">
                                <div class="px-2 md:px-8 flex flex-col justify-center items-center">
                                    <img
                                        class="w-full h-auto"
                                        :src="product.thumbnail.thumb"
                                        :srcset="product.thumbnail.srcset"
                                        :alt="product.name">
                                </div>
                            </div>
                        </div>
                    </template>
                </slick>
            </div>

            <div class="hidden pr-2 md:pr-0 md:flex items-center">
                <div class="cursor-pointer mb-4 w-6 h-6 md:w-16 md:h-16"
                     @click="next">
                    <img src="images/button-next.png" alt="Previous">
                </div>
            </div>
        </div>

        <Modal @close="close" v-if="selected_product_id !== null">
            <ProductPopUp :product="selected_product_id" />
        </Modal>
    </div>
</template>

<script>
    import Slick from 'vue-slick';
    import Modal from "./Modal";
    import ProductPopUp from "./ProductPopUp";

    export default {
        name: 'ProductCarousel',
        components: {
            Slick,
            ProductPopUp,
            Modal,
        },
        data() {
            return {
                slickOptions: {
                        // center: true,
                        dots: false,
                        infinite: true,
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        arrows: false,
                        responsive: [
                            {
                                breakpoint: 768,
                                settings: {
                                    dots: true,
                                    centerMode: true,
                                    centerPadding: '20%',
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                }
                            },
                            {
                                breakpoint: 992,
                                settings: {
                                    dots: false,
                                    slidesToShow: 2,
                                    slidesToScroll: 2,
                                }
                            }
                        ]
                    },
                products: '',
                selected_product_id: null,
            }
        },
        watch: {
            products: function (newProducts) {
                let currIndex = this.$refs.slick.currentSlide()

                this.$refs.slick.destroy()
                this.$nextTick(() => {
                    this.$refs.slick.create()
                    this.$refs.slick.goTo(currIndex, true)
                })
            }
        },
        mounted() {
            this.getProducts();
        },
        methods: {
            close() {
                this.selected_product_id = null;
            },
            next() {
                this.$refs.slick.next();
            },
            prev() {
                this.$refs.slick.prev();
            },
            selectProduct: function (product) {
                this.selected_product_id = product.id;
            },
            getProducts: function () {
                const self = this;
                axios
                    .get(route('api.product.index'))
                    .then(function (response) {
                        self.products = response.data.data;
                    });
            }
        },
    }
</script>
