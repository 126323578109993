<template>
    <div class="w-full flex flex-wrap bg-white rounded overflow-hidden">
        <div class="pt-4 px-4 w-full text-center lg:hidden flex justify-center">
            <h1
                class="text-center font-instaglam text-instaglam-pink text-2xl font-bold text-shadow-instaglam uppercase"
            >{{ model.name }}</h1>
        </div>

        <div class="
                    w-full flex flex-col items-center
                    lg:w-1/2
            ">
            <!-- Selected Image -->
            <div class="px-8 mt-4 lg:mb-4">
                <div class="bg-gray-100 p-1 lg:p-2 rounded-md shadow-md">
                    <img class="object-contain"
                         style="max-height:50vh;"
                         :src="selected_image.file"
                         :srcset="selected_image.srcset"
                         :alt="model.name">
                </div>
            </div>
            <!-- / Selected Image -->

            <!-- Nav -->
            <div class="h-full w-11/12 lg:w-5/6 px-8 lg:px-0 mt-4 lg:mt-0">
                <Slick
                    ref="productimages"
                    :options="slickNavOptions">
                    <div v-for="image in model.images"
                         :key="image.id"
                         @click="selected_image = image">
                        <div class="w-full flex justify-center px-1">
                            <div class="bg-gray-100 p-1 lg:p-2 rounded-md shadow-md">
                                <img :src="image.thumb" :alt="model.name" class="w-30 h-30 lg:w-36 lg:h-36">
                            </div>
                        </div>
                    </div>
                </Slick>
            </div>
            <!-- / Nav -->
        </div>

        <div class="w-full lg:w-1/2 mt-4 overflow-y-scroll px-2 pb-4"
             style="max-height: 95vh;">
            <div class="hidden lg:block">
                <h1
                    class="text-center font-instaglam text-instaglam-pink text-2xl xl:text-4xl font-bold text-shadow-instaglam uppercase"
                >{{ model.name }}</h1>
            </div>
            <div class="lg:mt-8" v-html="model.content"></div>
            <div class="mt-4 mb-8 text-center">
                <a :href="model.url"
                   target="_blank"
                   class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-instaglam-600 hover:bg-instaglam-500 focus:outline-none focus:border-instaglam-700 focus:shadow-outline-instaglam active:bg-instaglam-700 transition ease-in-out duration-150"
                >{{ model.link_text }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import Slick from 'vue-slick';

    export default {
        name: 'ProductPopUp',
        components: {
            Slick,
        },
        props: [
            'product'
        ],
        data() {
            return {
                model: '',
                selected_image: '',
                slickNavOptions: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: true,
                    centerMode: false,
                    focusOnSelect: true,
                    // vertical: true,
                    responsive: [
                        {
                            // breakpoint: 768,
                            breakpoint: 1600,
                            settings: {
                                slidesToShow: 3,
                                // vertical: false,
                                // arrows: true,
                                // centerMode: false,
                            }
                        },
                        {
                            // breakpoint: 768,
                            breakpoint: 1260,
                            settings: {
                                slidesToShow: 2,
                                // vertical: false,
                                // arrows: true,
                                // centerMode: false,
                            }
                        },
                        {
                            // breakpoint: 768,
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 4,
                                // vertical: false,
                                // arrows: true,
                                // centerMode: false,
                            }
                        },
                        {
                            // breakpoint: 768,
                            breakpoint: 850,
                            settings: {
                                slidesToShow: 3,
                                // vertical: false,
                                // arrows: true,
                                // centerMode: false,
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2,
                                // vertical: false,
                                // arrows: true,
                                // centerMode: false,
                            }
                        },
                    ],
                },
            }
        },
        watch: {
            model: function (newProducts) {
                let currIndex = this.$refs.productimages.currentSlide()

                this.$refs.productimages.destroy();

                this.$nextTick(() => {
                    this.$refs.productimages.create();
                    this.$refs.productimages.goTo(currIndex, true);
                });
            }
        },
        mounted() {
            this.getProduct();
        },
        methods: {
            next() {
                // this.$refs['slick-product-nav'].next();
            },
            prev() {
                // this.$refs['slick-product-nav'].prev();
            },
            getProduct() {
                const self = this;
                axios
                    .get(route('api.product.show', self.product))
                    .then(function (response) {
                        self.model = response.data.data;

                        if (typeof self.model.images[0] === 'undefined') {
                            // does not exist
                        } else {
                            self.selected_image = self.model.images[0];
                        }
                    });
            }
        },
    }
</script>
