<template>
    <Modal :open="open" @close="close">
        <div class="grid gap-1 grid-cols-4" v-if="open">
            <div class="col-span-4 md:col-span-3 relative">
                    <template v-if="selected !== ''">
                            <vue-core-video-player
                                                    :title="selected.name"
                                                    :autoplay="true"
                                                    :controls="true"
                                                    :cover="selected.video.thumb"
                                                    :src="selected.video.file"></vue-core-video-player>
                    </template>
            </div>
            <div class="col-span-4 md:col-span-1 relative">
                <div class="
                    relative scrollbar-none
                    md:absolute inset-0 h-full
                    md:flex md:flex-col
                    md:overflow-y-scroll md:overflow-x-hidden
                    overflow-y-hidden overflow-x-scroll
                    whitespace-no-wrap
                    ">
                    <div v-for="video in videos"
                         @click="selectVideo(video)"
                         class="relative group cursor-pointer p-1 w-1/2 md:w-1/3 inline-block md:w-full h-full md:h-auto">
                        <div class="group-hover:hidden absolute inset-0 bg-black bg-opacity-25"></div>

                        <div class="absolute inset-0 flex justify-center items-center"
                             style="z-index:1;">
                            <img class="w-16 lg:w-24 object-contain" src="images/video-play.png" alt="Play">
                        </div>

                        <div style="position: absolute;left: 5px;top: 5px;padding: 3px 6px;border-radius: 2px;"
                            class="text-sm font-semibold text-white bg-black bg-opacity-50">
                            {{ video.name }}
                        </div>

                        <div style="position: absolute;right: 5px;bottom: 5px;padding: 3px 6px;border-radius: 2px;"
                             class="text-xs font-semibold text-white bg-black bg-opacity-50">
                            {{ new Date(video.duration * 1000).toISOString().substr(14, 5) }}
                        </div>

                        <img class="h-full w-full object-cover" :src="video.video.thumb" :alt="video.name">
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
    import Modal from "./Modal";

    export default {
        name: 'VideoPlayer',
        components: {
            Modal,
        },
        props: [
            'open',
        ],
        data() {
            return {
                selected: '',
                videos: [],
            }
        },
        mounted() {
            // Get Videos
            this.getVideos();
        },
        methods: {
            selectVideo: function (video) {
                this.selected = video;
            },
            getVideos: function () {
                const self = this;
                axios
                    .get(route('api.video.index'))
                    .then(function (response) {
                        self.videos = response.data.data;

                        self.selected = self.videos.filter(video => video.primary).shift();
                    })
            },
            close: function () {
                this.$emit('close');
            },
        },
    }
</script>
