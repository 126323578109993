<template>
    <div class="mx-4">
        <div>
            <h4
                class="text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs">
                My makeup vibe is...</h4>
        </div>

        <div class="mt-4">
            <div class="grid gap-6 grid-cols-2 md:grid-cols-4 items-start">
                <div class="col-span-1 flex flex-col items-center" v-for="(image_file, option_name) in options">
                    <label
                        class="cursor-pointer rounded block border-4 border-transparent p-4"
                        v-bind:class="{ 'border-instaglam-pink shadow-lg': value.makeupvibe === option_name }">
                        <img class="w-24 lg:w-48  h-auto max-w-xs" :src="'images/questionnaire/makeupvibe/' + image_file"
                             :alt="option_name">

                        <input class="hidden" type="radio" name="makeupvibe" :value="option_name" v-model="value.makeupvibe">
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Form from "../Partials/Form";

    export default {
        components: {},
        props: [
            'value',
        ],
        data() {
            return {
                options: {
                    'beautiful_berry': 'beautiful_berry.png',
                    'bright_and_bold': 'bright_and_bold.png',
                    'gem_queen': 'gem_queen.png',
                    'perfectly_pretty': 'perfectly_pretty.png',
                },
                form: new Form(),
            }
        },
        mounted() {
            this.$emit('input', {
                ...this.value,
                key: 'makeupvibe',
            });
        },
        watch: {
            //
        },
        methods: {
            next() {
                this.$emit('next-step');
            },
        },
    }
</script>
