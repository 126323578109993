<template>
    <div class="mx-4 mt-8">
        <div>
            <h4
                class="text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs">
            What is your name?</h4>
        </div>

        <div class="mt-4">
            <div class="flex flex-col-reverse md:flex-row">
                <div class="mt-8 md:mt-4 md:w-2/3 mx-8 mx-4">
                    <div class="mt-1 relative rounded-md shadow-sm">
                        <label for="name">
                            <input id="name"
                                                         class="form-input block w-full sm:text-sm sm:leading-5"
                                                         placeholder="Claire"
                                                         aria-describedby="name-description"
                                                         v-model="value.name"
                            />
                        </label>
                    </div>
                    <p class="mt-2 text-sm text-gray-500" id="name-description">Just your first one is fine!</p>
                </div>
                <div class="flex justify-center mt-4 md:mt-0 md:block">
                    <img class="md:mt-4 w-56 md:w-full h-auto max-w-xs" src="images/logo.png" alt="Instaglam">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Form from "../Partials/Form";

    export default {
        components: {},
        props: [
            'value',
        ],
        data() {
            return {
                form: new Form(),
            }
        },
        mounted() {
            this.$emit('input', {
                ...this.value,
                key: 'name',
            });
        },
        watch: {
            //
        },
        methods: {
            next() {
                this.$emit('next-step');
            },
        },
    }
</script>
