var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", { attrs: { open: _vm.open }, on: { close: _vm.close } }, [
    _c(
      "div",
      { staticClass: "bg-white rounded overflow-hidden" },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.complete,
                expression: "!complete"
              }
            ]
          },
          [
            _c("div", { staticClass: "mt-2" }, [
              _c(
                "h1",
                {
                  staticClass:
                    "font-instaglam font-bold\n                    text-center\n                    text-3xl text-instaglam-pink text-shadow-instaglam-sm"
                },
                [
                  _vm._v(
                    "\n                    Question " +
                      _vm._s(_vm.step) +
                      " of " +
                      _vm._s(this.steps.length) +
                      "\n                "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "my-4" },
              [
                _c(_vm.currentComponent, {
                  tag: "component",
                  model: {
                    value: _vm.data,
                    callback: function($$v) {
                      _vm.data = $$v
                    },
                    expression: "data"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.error !== "",
                        expression: "error !== ''"
                      }
                    ],
                    staticClass: "mt-4 text-center text-red-500 font-semibold"
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.error) +
                        "\n                "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mt-4 flex justify-between px-8 pb-4" }, [
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "form-input",
                    class: { "cursor-not-allowed": this.step <= 1 },
                    attrs: { disabled: this.step <= 1 },
                    on: { click: _vm.prevStep }
                  },
                  [_vm._v("Previous\n                    ")]
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  { staticClass: "form-input", on: { click: _vm.nextStep } },
                  [
                    this.step < this.steps.length
                      ? _c("span", [_vm._v("Next")])
                      : _c("span", [_vm._v("Find My Style!")])
                  ]
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _vm.complete
          ? _c("Result", {
              on: { reset: _vm.startOver },
              model: {
                value: _vm.data,
                callback: function($$v) {
                  _vm.data = $$v
                },
                expression: "data"
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }