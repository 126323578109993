var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c("div", { staticClass: "w-full flex justify-center" }, [
        _c("div", { staticClass: "hidden pl-2 md:pl-0 md:flex items-center" }, [
          _c(
            "div",
            {
              staticClass: "cursor-pointer mb-4 w-6 h-6 md:w-16 md:h-16",
              on: { click: _vm.prev }
            },
            [
              _c("img", {
                attrs: { src: "images/button-previous.png", alt: "Previous" }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-full overflow-hidden" },
          [
            _c(
              "slick",
              {
                ref: "slick",
                attrs: { id: "product-carousel", options: _vm.slickOptions }
              },
              [
                _vm._l(_vm.products, function(product) {
                  return [
                    _c("div", { key: product.id }, [
                      _c(
                        "div",
                        {
                          staticClass: "cursor-pointer",
                          on: {
                            click: function($event) {
                              return _vm.selectProduct(product)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "px-2 md:px-8 flex flex-col justify-center items-center"
                            },
                            [
                              _c("img", {
                                staticClass: "w-full h-auto",
                                attrs: {
                                  src: product.thumbnail.thumb,
                                  srcset: product.thumbnail.srcset,
                                  alt: product.name
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ])
                  ]
                })
              ],
              2
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "hidden pr-2 md:pr-0 md:flex items-center" }, [
          _c(
            "div",
            {
              staticClass: "cursor-pointer mb-4 w-6 h-6 md:w-16 md:h-16",
              on: { click: _vm.next }
            },
            [
              _c("img", {
                attrs: { src: "images/button-next.png", alt: "Previous" }
              })
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _vm.selected_product_id !== null
        ? _c(
            "Modal",
            { on: { close: _vm.close } },
            [
              _c("ProductPopUp", {
                attrs: { product: _vm.selected_product_id }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }