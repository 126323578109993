<template>
    <div class="mx-4">
        <div>
            <h4
                class="text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs">
                What is your favourite colour?</h4>
        </div>

        <div class="mt-4">
            <div class="grid gap-4 grid-cols-2 md:grid-cols-4 ">

                <div class="col-span-1 p-4 flex flex-col items-center" v-for="(colour_code, colour_name) in colours">
                    <label
                        class="cursor-pointer inline-block rounded-full w-24 lg:w-48 h-24 lg:h-48 border-4 border-transparent"
                        :style="'background-color: ' + colour_code + ';'"
                        v-bind:class="{ 'border-black shadow-lg': value.colour === colour_name }">
                        <input class="hidden" type="radio" name="colour" :value="colour_name" v-model="value.colour">
                    </label>
                    <div class="text-center mt-2">
                        {{ colour_name | capitalize }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Form from "../Partials/Form";

    export default {
        components: {},
        props: [
            'value',
        ],
        data() {
            return {
                colours: {
                    'pink': '#e40694',
                    'purple': '#9362a9',
                    'green': '#6cc5ac',
                    'coral': '#f88379',
                },
                form: new Form(),
            }
        },
        mounted() {
            this.$emit('input', {
                ...this.value,
                key: 'colour',
            });
        },
        watch: {
            //
        },
        methods: {
            next() {
                this.$emit('next-step');
            },
        },
    }
</script>
