<template>
    <div class="mx-4">
        <div>
            <h4
                class="text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs">
                What is your favourite drink?</h4>
        </div>


        <div class="mt-4">
            <div class="grid gap-6 grid-cols-2 md:grid-cols-4">
                <div class="col-span-1 flex flex-col items-center" v-for="(image_file, drink_name) in drinks">
                    <label
                        class="cursor-pointer inline-block rounded-full border-4 border-transparent"
                        v-bind:class="{ 'border-black bg-black shadow-lg': value.drink === drink_name }">
                        <img class="w-24 lg:w-48  h-auto max-w-xs"
                             :src="'images/questionnaire/drink/' + image_file"
                             :alt="drink_name">
                        <input class="hidden" type="radio" name="drink" :value="drink_name" v-model="value.drink">
                    </label>
                    <div class="text-center">
                        {{ drink_name | deslug | capitalize_words }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Form from "../Partials/Form";

    export default {
        components: {},
        props: [
            'value',
        ],
        data() {
            return {
                drinks: {
                    'juice': 'juice.png',
                    'slushy': 'slushy.png',
                    'smoothie': 'smoothie.png',
                    'strawberry_milkshake': 'strawberry_milkshake.png',
                },
                form: new Form(),
            }
        },
        mounted() {
            this.$emit('input', {
                ...this.value,
                key: 'drink',
            });
        },
        watch: {
            //
        },
        methods: {
            next() {
                this.$emit('next-step');
            },
        },
    }
</script>
