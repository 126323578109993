<template>
    <div class="mx-4 my-8">
        <div>
            <h4
                class="text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs">
                Your Instaglam Style!</h4>
        </div>

        <div class="mt-6">
            <template v-if="image_url === ''">
                <div class="text-center">
                    <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                    <span class="sr-only">Loading...</span>
                </div>
            </template>

            <template v-else>
                <div class="bg-instaglam-pink p-1 rounded shadow-md">
                    <a :href="image_url"
                       target="_blank">
                        <img class="max-w-xl object-contain w-full" :src="image_url" alt="My Instaglam Style">
                    </a>
                </div>

                <div class="mt-4 flex justify-center p-1 md:p-2">
                    <a :href="image_url + '?download=1'"
                       target="_blank"
                       class="shadow-md mx-2 md:mx-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-instaglam-600 hover:bg-instaglam-500 focus:outline-none focus:border-instaglam-700 focus:shadow-outline-instaglam active:bg-instaglam-700 transition ease-in-out duration-150"
                    >Save Print Out</a>

                    <a :href="image_url"
                       target="_blank"
                       class="shadow-md mx-2 md:mx-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-instaglam-600 hover:bg-instaglam-500 focus:outline-none focus:border-instaglam-700 focus:shadow-outline-instaglam active:bg-instaglam-700 transition ease-in-out duration-150"
                    >View Print Out</a>

                    <button type="button"
                            class="shadow-md mx-2 md:mx-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-instaglam-600 hover:bg-instaglam-500 focus:outline-none focus:border-instaglam-700 focus:shadow-outline-instaglam active:bg-instaglam-700 transition ease-in-out duration-150"
                            v-on:click.stop="$emit('reset')">
                        Start Over
                    </button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import Form from "../Partials/Form";

    export default {
        components: {},
        props: [
            'value',
        ],
        data() {
            return {
                form: new Form(),
                image_url: '',
            }
        },
        mounted() {
            const self = this;
            this.form.post(route('api.printout.create'), this.value)
                .then(response => {
                    self.image_url = response.url;
                })
                .catch(error => {
                    //
                })
        },
        watch: {
            //
        },
        methods: {
            //
        },
    }
</script>
