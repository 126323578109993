<template>
    <div v-show="open">
        <div class="z-10 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div class="p-4">
                <div class="rounded-lg p-2 bg-black relative"
                     v-click-outside="close">
                    <!-- Removed: max-w-screen-xl-->

                    <div class="absolute top-0 right-0 z-20"
                         style="transform:translateX(25%) translateY(-25%);">
                        <button @click="close"
                                class="inline-flex justify-center items-center bg-white w-8 h-8 rounded-full bg-instaglam-pink text-white">
                            <i class="fas fa-lg fa-times"></i>
                        </button>
                    </div>

                    <div class="overflow-x-hidden overflow-y-scroll scrollbar-none" style="max-height:95vh;max-width:95vw;">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ClickOutside from 'vue-click-outside'

    export default {
        name: 'Modal',
        directives: {
            ClickOutside
        },
        props: {
            open: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                canClose: false,
            }
        },
        mounted() {
            const self = this;

            if(self.open) {
                self.addClass();

                setTimeout(function () {
                    self.canClose = true;
                }, 10);
            }
        },
        watch: {
            open: function (newVal, oldVal) {
                const self = this;

                if (newVal) {
                    self.canClose = false;
                    setTimeout(function () {
                        self.canClose = true;
                    }, 10);
                } else {
                    self.canClose = false;
                }

                if (newVal) {
                    self.addClass();
                } else {
                    self.removeClass();
                }
            },
        },
        methods: {
            addClass: function() {
                document.body.style.top = `-${window.scrollY}px`;
                document.body.style.position = 'fixed';
                document.body.classList.add('modal-open');
            },
            removeClass: function() {
                const scrollY = document.body.style.top;
                document.body.classList.remove('modal-open');
                document.body.style.position = '';
                document.body.style.top = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
            },
            close: function () {
                if (this.canClose && this.open) {
                    this.$emit('close');
                }
            }
        },
        beforeDestroy() {
            if(document.body.classList.contains('modal-open')) {
                this.removeClass();
            }
        }
    }
</script>
