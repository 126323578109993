<template>
    <div class="mx-4 mt-8">
        <div>
            <h4
                class="text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs">
                Describe yourself in three words</h4>
        </div>

        <div class="mt-8 flex flex-col items-center">
            <div class="mt-2 relative rounded-md shadow-sm">
                <label for="describe_word_1">
                    <span
                        class="text-center inline-block w-4 font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs">
                        1.</span>
                    <input id="describe_word_1"
                           class="ml-2 form-input sm:text-sm sm:leading-5 w-64"
                           v-model="value.describe_word_1"
                    />
                </label>
            </div>

            <div class="mt-2 relative rounded-md shadow-sm">
                <label for="describe_word_2">
                    <span
                        class="text-center inline-block w-4 font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs">
                        2.</span>
                    <input id="describe_word_2"
                           class="ml-2 form-input sm:text-sm sm:leading-5 w-64"
                           v-model="value.describe_word_2"
                    />
                </label>
            </div>

            <div class="mt-2 relative rounded-md shadow-sm">
                <label for="describe_word_3">
                    <span
                        class="text-center inline-block w-4 font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs">
                        3.</span>
                    <input id="describe_word_3"
                           class="ml-2 form-input sm:text-sm sm:leading-5 w-64"
                           v-model="value.describe_word_3"
                    />
                </label>
            </div>

        </div>
    </div>
</template>

<script>
    import Form from "../Partials/Form";

    export default {
        components: {},
        props: [
            'value',
        ],
        data() {
            return {
                form: new Form(),
            }
        },
        mounted() {
            this.$emit('input', {
                ...this.value,
                key: [
                    'describe_word_1',
                    'describe_word_2',
                    'describe_word_3',
                ],
            });
        },
        watch: {
            //
        },
        methods: {
            next() {
                this.$emit('next-step');
            },
        },
    }
</script>
