var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mx-4 mt-8" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "mt-8 flex flex-col items-center" }, [
      _c("div", { staticClass: "mt-2 relative rounded-md shadow-sm" }, [
        _c("label", { attrs: { for: "describe_word_1" } }, [
          _c(
            "span",
            {
              staticClass:
                "text-center inline-block w-4 font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs"
            },
            [_vm._v("\n                    1.")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value.describe_word_1,
                expression: "value.describe_word_1"
              }
            ],
            staticClass: "ml-2 form-input sm:text-sm sm:leading-5 w-64",
            attrs: { id: "describe_word_1" },
            domProps: { value: _vm.value.describe_word_1 },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.value, "describe_word_1", $event.target.value)
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-2 relative rounded-md shadow-sm" }, [
        _c("label", { attrs: { for: "describe_word_2" } }, [
          _c(
            "span",
            {
              staticClass:
                "text-center inline-block w-4 font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs"
            },
            [_vm._v("\n                    2.")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value.describe_word_2,
                expression: "value.describe_word_2"
              }
            ],
            staticClass: "ml-2 form-input sm:text-sm sm:leading-5 w-64",
            attrs: { id: "describe_word_2" },
            domProps: { value: _vm.value.describe_word_2 },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.value, "describe_word_2", $event.target.value)
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-2 relative rounded-md shadow-sm" }, [
        _c("label", { attrs: { for: "describe_word_3" } }, [
          _c(
            "span",
            {
              staticClass:
                "text-center inline-block w-4 font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs"
            },
            [_vm._v("\n                    3.")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value.describe_word_3,
                expression: "value.describe_word_3"
              }
            ],
            staticClass: "ml-2 form-input sm:text-sm sm:leading-5 w-64",
            attrs: { id: "describe_word_3" },
            domProps: { value: _vm.value.describe_word_3 },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.value, "describe_word_3", $event.target.value)
              }
            }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "h4",
        {
          staticClass:
            "text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs"
        },
        [_vm._v("\n            Describe yourself in three words")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }