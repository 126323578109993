<template>
    <div class="mx-4">
        <div>
            <h4
                class="text-center font-instaglam text-instaglam-pink text-xl font-bold uppercase text-shadow-instaglam-xs">
                I Love...</h4>
        </div>


        <div class="mt-4">
            <div class="grid gap-6 grid-cols-2 md:grid-cols-4 items-end">
                <div class="col-span-1 flex flex-col items-center" v-for="(image_file, option_name) in options">
                    <label
                        class="cursor-pointer inline-block rounded-full border-4 border-transparent"
                        v-bind:class="{ 'border-black bg-black shadow-lg': value.love === option_name }">
                        <img class="w-24 lg:w-48  h-auto max-w-xs"
                             :src="'images/questionnaire/love/' + image_file"
                             :alt="option_name">
                        <input class="hidden" type="radio" name="love" :value="option_name" v-model="value.love">
                    </label>
                    <div class="text-center">
                        {{ option_name | deslug | capitalize_words }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Form from "../Partials/Form";

    export default {
        components: {},
        props: [
            'value',
        ],
        data() {
            return {
                options: {
                    'fairies': 'fairies.png',
                    'jewels': 'jewels.png',
                    'rainbows': 'rainbows.png',
                    'unicorns': 'unicorns.png',
                },
                form: new Form(),
            }
        },
        mounted() {
            this.$emit('input', {
                ...this.value,
                key: 'love',
            });
        },
        watch: {
            //
        },
        methods: {
            next() {
                this.$emit('next-step');
            },
        },
    }
</script>
