// import PortalVue from 'portal-vue';
// Vue.use(PortalVue);

import VueCoreVideoPlayer from 'vue-core-video-player';
Vue.use(VueCoreVideoPlayer);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
});

Vue.filter('capitalize_words', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
});

Vue.filter('slug', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.replace(/ /g,"_");
});

Vue.filter('deslug', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.replace(/_/g," ");
});

Vue.mixin({
    methods: {
        route: (name, params, absolute) => window.route(name,params,absolute),
    }
});

Vue.component('questionnaire', require('../components/Questionnaire.vue').default);
Vue.component('productcarousel', require('../components/ProductCarousel.vue').default);
Vue.component('videoplayer', require('../components/VideoPlayer.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
const app = new Vue({
    el: '#app',
    data: {
        modal: '',
    },
    methods: {
        click: function(modal) {
            this.modal = modal;

            // document.body.style.top = `-${window.scrollY}px`;
            // document.body.style.position = 'fixed';
            // document.body.classList.add('modal-open');
        },
        close: function() {
            this.modal = '';

            // const scrollY = document.body.style.top;
            // document.body.classList.remove('modal-open');
            // document.body.style.position = '';
            // document.body.style.top = '';
            // window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    },
});
